"use client";

import dynamic from "next/dynamic";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Toastify } from "@src/components";
import useMutation from "@src/hooks/use-mutation";
import { useLayoutContext } from "@src/layouts/provider";
import CartVoucherModel from "@src/models/cartVoucher";
import HomeSectionContainer from "@src/sections/home/home-section-container";
import { useEffect, useState, memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton, Box } from "@mui/material";
import useResponsive from "@src/hooks/use-responsive";

// Move VoucherItem to client-side only
const VoucherItem = dynamic(() => import("@src/components/VoucherItem"), {
  ssr: false,
  loading: () => <Skeleton variant="rectangular" width={280} height={120} sx={{
    borderRadius: 2,
    flexShrink: 0
  }} />
});
const VOUCHER_DIMENSIONS = {
  mobile: {
    width: 280,
    height: 120,
    gap: 8
  },
  desktop: {
    width: 320,
    height: 140,
    gap: 16
  }
} as const;
const WrapperTabs = styled(Tabs)({
  "& .MuiTabs-scroller::-webkit-scrollbar": {
    display: "none"
  },
  "& .MuiTabs-scroller": {
    minHeight: "unset"
  },
  "& .MuiTabs-flexContainer": {
    gap: {
      xs: VOUCHER_DIMENSIONS.mobile.gap,
      md: VOUCHER_DIMENSIONS.desktop.gap
    }
  },
  "& .MuiTabScrollButton-root": {
    width: "20px",
    height: "20px",
    backgroundColor: "#7D7D7D",
    borderRadius: "50%",
    margin: "auto 2px",
    "& svg": {
      color: "white",
      fontSize: "18px"
    }
  }
});
const VoucherContainer = styled(HomeSectionContainer)({
  "& .MuiSkeleton-root": {
    transform: "scale(1)" // Prevent skeleton animation from causing layout shift
  }
});
export const VoucherSkeleton = memo(() => {
  const isMobile = useResponsive().isMobile;
  const dimensions = isMobile ? VOUCHER_DIMENSIONS.mobile : VOUCHER_DIMENSIONS.desktop;
  return <VoucherContainer>
      <Skeleton variant="text" width={200} height={32} sx={{
      transform: "scale(1)"
    }} />
      <Stack mt={{
      xs: 1,
      md: 2
    }} mb={{
      xs: 1,
      md: 0
    }} direction="row" spacing={{
      xs: VOUCHER_DIMENSIONS.mobile.gap,
      md: VOUCHER_DIMENSIONS.desktop.gap
    }} sx={{
      overflow: "hidden",
      width: "100%",
      height: {
        xs: VOUCHER_DIMENSIONS.mobile.height,
        md: VOUCHER_DIMENSIONS.desktop.height
      }
    }}>
        {Array(4).fill(0).map((_, index) => <Skeleton key={index} variant="rectangular" width={{
        xs: VOUCHER_DIMENSIONS.mobile.width,
        md: VOUCHER_DIMENSIONS.desktop.width
      }} height="100%" sx={{
        borderRadius: 2,
        flexShrink: 0,
        transform: "scale(1)"
      }} />)}
      </Stack>
    </VoucherContainer>;
});
VoucherSkeleton.displayName = "VoucherSkeleton";
interface VoucherListProps {
  voucherList: CartVoucherModel[];
  onSaleColor: string | null;
  voucherSelected: CartVoucherModel | null;
  isLoadingSave: boolean;
  onAddVoucher: (voucher: CartVoucherModel) => Promise<void>;
}
const VoucherList = memo(({
  voucherList,
  onSaleColor,
  voucherSelected,
  isLoadingSave,
  onAddVoucher
}: VoucherListProps) => {
  const isMobile = useResponsive().isMobile;
  const dimensions = isMobile ? VOUCHER_DIMENSIONS.mobile : VOUCHER_DIMENSIONS.desktop;
  const renderedVouchers = useMemo(() => voucherList.map(item => <Box key={item.code} sx={{
    width: {
      xs: VOUCHER_DIMENSIONS.mobile.width,
      md: VOUCHER_DIMENSIONS.desktop.width
    },
    height: {
      xs: VOUCHER_DIMENSIONS.mobile.height,
      md: VOUCHER_DIMENSIONS.desktop.height
    }
  }}>
        <VoucherItem type="card" onSaleColor={onSaleColor} data={item} loading={isLoadingSave && item.id === voucherSelected?.id} onAddVoucher={onAddVoucher} />
      </Box>), [voucherList, onSaleColor, voucherSelected, isLoadingSave, onAddVoucher, isMobile]);
  return <WrapperTabs variant="scrollable" scrollButtons visibleScrollbar={true} aria-label="scrollable voucher tabs" allowScrollButtonsMobile={true} TabIndicatorProps={{
    style: {
      display: 'none'
    }
  }}>
      {renderedVouchers}
    </WrapperTabs>;
});
VoucherList.displayName = "VoucherList";
interface VoucherComponentProps {
  data: CartVoucherModel[];
}
const VoucherComponent = ({
  data = []
}: VoucherComponentProps) => {
  const {
    onSaleColor
  } = useLayoutContext();
  const {
    t
  } = useTranslation();
  const {
    mutation,
    loading: isLoadingSave
  } = useMutation();
  const [voucherSelected, setVoucherSelected] = useState<CartVoucherModel | null>(null);
  const [voucherList, setVoucherList] = useState<CartVoucherModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Initialize data immediately to avoid hydration mismatch
    setVoucherList(data);
    // Delay setting loading state to ensure smooth transition
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 100);
    return () => clearTimeout(timer);
  }, [data]);
  const handleAddVoucher = useCallback(async (voucher: CartVoucherModel) => {
    if (voucher?.id) {
      setVoucherSelected(voucher);
      await mutation({
        url: `/auth/vouchers/${voucher.id}/save`,
        method: "POST",
        onSuccess: res => {
          if (res) {
            Toastify.success(res?.message || "Đã lưu voucher");
            setVoucherList(prevList => prevList.map(item => item.id === voucher.id ? {
              ...item,
              saved: true
            } as CartVoucherModel : item));
            setVoucherSelected(null);
          }
        },
        onFailed: () => {
          setVoucherSelected(null);
        }
      });
    }
  }, [mutation]);
  if (isLoading) {
    return <VoucherSkeleton />;
  }
  if (!voucherList || voucherList.length === 0) {
    return null;
  }
  return <VoucherContainer data-sentry-element="VoucherContainer" data-sentry-component="VoucherComponent" data-sentry-source-file="voucher.tsx">
      <Typography variant="h3" component="h3" sx={{
      fontSize: {
        xs: "16px",
        md: "24px"
      },
      fontWeight: 700,
      height: 32,
      // Match skeleton height
      display: "flex",
      alignItems: "center"
    }} data-sentry-element="Typography" data-sentry-source-file="voucher.tsx">
        {t("home_voucher_title")}
      </Typography>
      <Stack mt={{
      xs: 1,
      md: 2
    }} mb={{
      xs: 1,
      md: 0
    }} spacing={2} sx={{
      overflow: "hidden",
      width: "100%",
      height: {
        xs: VOUCHER_DIMENSIONS.mobile.height,
        md: VOUCHER_DIMENSIONS.desktop.height
      }
    }} data-sentry-element="Stack" data-sentry-source-file="voucher.tsx">
        <VoucherList voucherList={voucherList} onSaleColor={onSaleColor} voucherSelected={voucherSelected} isLoadingSave={isLoadingSave} onAddVoucher={handleAddVoucher} data-sentry-element="VoucherList" data-sentry-source-file="voucher.tsx" />
      </Stack>
    </VoucherContainer>;
};
export default memo(VoucherComponent);