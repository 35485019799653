import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NextImage from "next/image";
import { useTranslation } from "react-i18next";
const EmptyProduct = ({
  isOnSale
}) => {
  const {
    t
  } = useTranslation();
  return <Stack paddingY="80px" paddingX="16px" width="100%" direction="column" justifyContent="center" alignItems="center" gap={2} data-sentry-element="Stack" data-sentry-component="EmptyProduct" data-sentry-source-file="empty-product.tsx">
      <Box sx={{
      width: 240,
      height: 240,
      position: "relative",
      overflow: "hidden"
    }} data-sentry-element="Box" data-sentry-source-file="empty-product.tsx">
        <NextImage src="/assets/images/empty-sale.png" width={240} height={240} alt="Empty flash sale products" data-sentry-element="NextImage" data-sentry-source-file="empty-product.tsx" />
      </Box>
      {isOnSale ? <>
          <Typography variant="body1" sx={{
        textAlign: "center",
        fontWeight: 700
      }}>
            {t("upcoming_sale")}
          </Typography>
          <Typography variant="body2" sx={{
        textAlign: "center"
      }}>
            {t("upcoming_sale_details")}
          </Typography>
        </> : <Typography variant="body2" sx={{
      textAlign: "center"
    }}>
          {t("upcoming_product_update")}
        </Typography>}
    </Stack>;
};
export default EmptyProduct;