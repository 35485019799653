"use client";

import { Box, Button, Grid, Skeleton, Stack } from "@mui/material";
import { CancelButton, Icons, ProductCard } from "@src/components";
import { API_URL, CONFIG_COLLECTION } from "@src/constants";
import EmptyProduct from "./empty-product";
import { useRouter } from "next/navigation";
import { useCallback, useEffect, useState } from "react";
import { useLayoutContext } from "@src/layouts/provider";
import { useTranslation } from "react-i18next";
import fetchClient from "@src/api/lib/fetch-client";
const TabSkeleton = () => <Stack direction="row" spacing={8} justifyContent="center" alignItems="center" data-sentry-element="Stack" data-sentry-component="TabSkeleton" data-sentry-source-file="product-tabs.tsx">
    <Skeleton variant="text" width={150} height={32} data-sentry-element="Skeleton" data-sentry-source-file="product-tabs.tsx" />
    <Skeleton variant="text" width={150} height={32} data-sentry-element="Skeleton" data-sentry-source-file="product-tabs.tsx" />
    <Skeleton variant="text" width={150} height={32} data-sentry-element="Skeleton" data-sentry-source-file="product-tabs.tsx" />
  </Stack>;
const ProductTabsSkeleton = () => <>
    <Box mb={2} data-sentry-element="Box" data-sentry-source-file="product-tabs.tsx">
      <TabSkeleton data-sentry-element="TabSkeleton" data-sentry-source-file="product-tabs.tsx" />
    </Box>
    <Grid container spacing={{
    xs: 1,
    md: 3
  }} sx={{
    marginLeft: {
      xs: "-8px",
      md: "-24px"
    },
    width: {
      xs: "calc(100% + 8px)",
      md: "calc(100% + 24px)"
    }
  }} data-sentry-element="Grid" data-sentry-source-file="product-tabs.tsx">
      {new Array(8).fill(0).map((_, index) => <Grid key={index} item xs={6} lg={3}>
          <Box width="100%">
            <Skeleton variant="rectangular" width="100%" height={330} sx={{
          borderRadius: 1
        }} />
            <Box sx={{
          pt: 1
        }}>
              <Skeleton variant="text" width="100%" height={24} />
              <Skeleton variant="text" width="60%" height={24} />
            </Box>
            <Box sx={{
          pt: 0.5
        }}>
              <Skeleton variant="text" width="40%" height={24} />
            </Box>
          </Box>
        </Grid>)}
    </Grid>
    <Box display="flex" justifyContent="center" mt={6} data-sentry-element="Box" data-sentry-source-file="product-tabs.tsx">
      <Skeleton variant="rectangular" width={328} height={40} sx={{
      borderRadius: 40
    }} data-sentry-element="Skeleton" data-sentry-source-file="product-tabs.tsx" />
    </Box>
  </>;
const CategoryTitle = ({
  children,
  active,
  onClick
}) => {
  const activeStyle = {
    color: "black",
    fontWeight: 700,
    backgroundPosition: "100% 100%",
    backgroundSize: "100% 3px !important",
    "& svg": {
      opacity: 1
    }
  };
  return <Button variant="text" disableRipple sx={{
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: {
      xs: 16,
      md: 22
    },
    fontWeight: 400,
    lineHeight: "32px",
    minWidth: {
      xs: "auto",
      md: 150
    },
    border: "none",
    borderRadius: 0,
    width: "fit-content",
    padding: 0,
    textTransform: "none",
    whiteSpace: "nowrap",
    display: "inline-block",
    paddingBottom: "3px",
    backgroundImage: "linear-gradient(#000 0 0)",
    backgroundPosition: "0 100%",
    backgroundSize: "0% 2px",
    backgroundRepeat: "no-repeat",
    transition: "background-size 0.3s,  background-position 0s 0.3s",
    "& svg": {
      opacity: 0.5
    },
    "&:hover": {
      color: "black",
      backgroundPosition: "100% 100%",
      backgroundSize: "100% 2px",
      backgroundColor: "transparent",
      "& svg": {
        opacity: 1
      }
    },
    ...(active && activeStyle)
  }} onClick={onClick} data-sentry-element="Button" data-sentry-component="CategoryTitle" data-sentry-source-file="product-tabs.tsx">
      {children}
    </Button>;
};
interface TabType {
  label: string;
  url: string;
  key: string;
}
const ProductTabs = () => {
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const {
    config
  } = useLayoutContext();
  const [tabActive, setTabActive] = useState<TabType>(config?.on_sale ? CONFIG_COLLECTION.ON_SALE : CONFIG_COLLECTION.NEW_IN);
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleChangeTab = tab => async () => {
    setTabActive(tab);
    setLoading(true);
  };
  const loadProduct = async type => {
    try {
      const response = await fetchClient({
        method: "GET",
        url: API_URL.PAGINATE_PRODUCT,
        queries: {
          type: type
        }
      });
      setEntries(response.data.items);
    } catch (error) {
      console.error("Failed to load products:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (tabActive) {
      loadProduct(tabActive.key);
    }
  }, [tabActive]);
  const handleViewAll = useCallback(() => {
    if (tabActive) {
      router.push(tabActive.url);
    }
  }, [tabActive?.key]);
  if (loading) {
    return <ProductTabsSkeleton />;
  }
  return <>
      <Stack mb={2} justifyContent="center" alignItems="center" spacing={{
      xs: 4,
      sm: 8,
      md: 14
    }} data-sentry-element="Stack" data-sentry-source-file="product-tabs.tsx">
        {config?.on_sale && <CategoryTitle active={tabActive?.key === CONFIG_COLLECTION.ON_SALE.key} onClick={handleChangeTab(CONFIG_COLLECTION.ON_SALE)}>
            ON SALE
          </CategoryTitle>}
        <CategoryTitle active={tabActive?.key === CONFIG_COLLECTION.NEW_IN.key} onClick={handleChangeTab(CONFIG_COLLECTION.NEW_IN)} data-sentry-element="CategoryTitle" data-sentry-source-file="product-tabs.tsx">
          NEW ARRIVALS
        </CategoryTitle>
        <CategoryTitle active={tabActive?.key === CONFIG_COLLECTION.BEST_SELLER.key} onClick={handleChangeTab(CONFIG_COLLECTION.BEST_SELLER)} data-sentry-element="CategoryTitle" data-sentry-source-file="product-tabs.tsx">
          BEST SELLERS
        </CategoryTitle>
        {!config?.on_sale && <CategoryTitle active={tabActive?.key === CONFIG_COLLECTION.ON_SALE.key} onClick={handleChangeTab(CONFIG_COLLECTION.ON_SALE)}>
            ON SALE
          </CategoryTitle>}
      </Stack>
      <Grid container spacing={{
      xs: 1,
      md: 3
    }} sx={{
      marginLeft: {
        xs: "-8px",
        md: "-24px"
      },
      width: {
        xs: "calc(100% + 8px)",
        md: "calc(100% + 24px)"
      }
    }} data-sentry-element="Grid" data-sentry-source-file="product-tabs.tsx">
        {entries?.length === 0 ? <EmptyProduct isOnSale={tabActive?.key === CONFIG_COLLECTION.ON_SALE.key} /> : entries.map((product, index) => <Grid key={index} item xs={6} lg={3}>
              <ProductCard product={product} />
            </Grid>)}
      </Grid>
      {entries?.length > 0 && <CancelButton type="button" height="40px" label={t("view_all")} endIcon={<Icons name="arrow-right" />} minHeight="40px" sx={{
      margin: {
        xs: "24px auto 0",
        md: "48px auto 0"
      },
      minWidth: {
        xs: "220px !important",
        md: "328px !important"
      }
    }} onClick={handleViewAll} />}
    </>;
};
export default ProductTabs;